$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.sectionFour {
	padding: 80px 0px 120px;
	> div {
		img {
			margin: 100px 0px 150px 50px;
			width: 100%;
			@media screen and (max-width: $breakpoint-desktop) {
				margin: 100px 0px 100px;
			}
		}
	}
	.subDetails {
		@media screen and (max-width: $breakpoint-bigPhone) {
			font-size: 1.6rem;
		}
		text-align: center;
		margin-bottom: 35px;
		font-family: "Ubuntu";
		text-transform: capitalize;
		font-size: 2.1rem;
		&__green {
			color: var(--primary-green);
			font-weight: 600;
		}
	}
}
