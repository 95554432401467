$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.sectionThree {
	padding: 50px 0px;
	.subDetails {
		@media screen and (max-width: $breakpoint-bigPhone) {
			font-size: 1.6rem;
		}
		text-align: center;
		margin-bottom: 35px;
		font-family: "Ubuntu";
		text-transform: capitalize;
		font-size: 2.1rem;
		&__green {
			color: var(--primary-green);
			font-weight: 600;
			margin-left: 10px;
		}
	}
	&__staffList {
		width: 70%;
		display: grid;
		grid-column-gap: 60px;
		grid-row-gap: 100px;
		margin: auto;

		grid-template-columns: 1fr 1fr 1fr;
		@media screen and (max-width: $breakpoint-desktop) {
			grid-template-columns: 1fr 1fr;
		}
		@media screen and (max-width: $breakpoint-tablet) {
			grid-template-columns: 1fr;
			grid-row-gap: 40px;
		}
	}
	@media screen and (max-width: $breakpoint-bigPhone) {
		width: 90%;
	}
}
.ceoCard {
	margin-top: 60px;
	margin-bottom: 100px;
	width: 20%;
	img {
		margin-bottom: 12px;
	}
	@media screen and (max-width: $breakpoint-desktop) {
		width: 30%;
	}
	@media screen and (max-width: $breakpoint-tablet) {
		width: 70%;
	}
}
