$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.textCluster {
	&__subheading {
		margin-bottom: 35px;
	}
	&__description {
		margin-bottom: 35px;
	}
	&__btn {
	}
	&__image {
		width: 70%;

		@media screen and (max-width: $breakpoint-desktop) {
			width: 100%;
		}
	}
}
