.gradient {
	background-image: linear-gradient(to right, #5a4fcf, #ff6f61);
	color: white;
	padding: 5px 15px;
	border-radius: 15px;
	width: fit-content;
	display: flex;
	margin: auto;
	font-size: 12px;
	opacity: 0.8;
	font-family: "Ubuntu";
	font-weight: 100;
	span {
		font-weight: 700;
		margin-left: 5px;
	}
}
