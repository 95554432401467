$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.keyHighlights {

	box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.263);
	border-radius: 7px;
	@media screen and (max-width: $breakpoint-desktop) {
		margin-bottom: 80px;
		width: 100%;
	}
	&__parent {
		padding: 12px;
		box-shadow: 0px 0px 200px 0px #ffe6e4;
		&__title {
			color: var(--primary-green);
			font-family: "Open Sans";
			text-transform: capitalize;
			font-size: 17px;
			margin-bottom: 15px;
		}
		&__metric {
			font-family: "Open Sans";
			color: var(--primary-green);
			margin-top: 15px;
			font-weight: 800;
		}
	}
}
