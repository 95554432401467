$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.staff {
	@media screen and (max-width: $breakpoint-desktop) {
		margin-bottom: 80px;
	}
	position: relative;

	&__parent {
		border-radius: 64px;
		position: relative;
		background-color: white;
		&::before {
			content: "";
			position: absolute;
			background: linear-gradient(
				90deg,
				#b6afff 0%,
				rgba(255, 111, 0, 0.771) 120%
			);
			width: calc(100% + 12px);
			height: calc(100% + 12px);
			top: -6px;
			left: -6px;
			z-index: -1;
			border-radius: 64px;
		}
		padding: 30px;
		height: 100%;
		box-shadow: 0px 0px 200px 0px #d6d2ff;
		&__staffName {
			font-family: "Open Sans";
			text-transform: capitalize;
			font-size: 22px;
			font-weight: 800;
			@media screen and (max-width: $breakpoint-bigPhone) {
				font-size: 15px;
			}
		}
		&__staffPosition {
			font-family: "Open Sans";
			text-transform: capitalize;
			font-size: 18px;
			@media screen and (max-width: $breakpoint-bigPhone) {
				font-size: 12px;
			}
		}
		&__staffImage {
			width: 70%;
			margin-bottom: 12px;
			height: 70%;
		}
	}
	.popup {
		border-radius: 17px;
		width: 220px;
		z-index: 2;
		position: absolute;
		top: -5%;
		left: 70%;
		background-color: white;
		@media screen and (max-width: $breakpoint-desktop) {
			left: 0%;
			top: 70%;
		}

		animation: rotation 0.8s infinite linear alternate;

		ul {
			padding: 17px 30px;
			width: 100%;
			border-radius: 12px;
			background-color: white;
			li {
				font-weight: 100;
				font-family: "Open Sans";
				font-size: 14px;
			}
			z-index: 3;
		}
		::before {
			border-radius: 12px;
			content: "";
			position: absolute;
			background: linear-gradient(
				90deg,
				#b6afff 0%,
				rgba(255, 111, 0, 0.771) 120%
			);
			width: calc(100% + 2px);
			height: calc(100% + 2px);
			top: -1px;
			left: -1px;
			z-index: -2;
		}
	}
}

@keyframes rotation {
	from {
		transform: translateY(7vw);
	}
	to {
		transform: translateY(10vw);
	}
}
