$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.sectionFour {
	width: 80%;
	justify-content: space-between;
	margin: 240px 0px 140px;
	@media screen and (max-width: $breakpoint-desktop) {
		margin: 70px 0px;
	}
	> div {
		width: 23%;
		box-shadow: 10px 10px 100px 10px #ffe6e4;
		border-radius: 7%;
		img {
			width: 100%;
			border-radius: 7%;
		}
	}
	@media screen and (max-width: $breakpoint-tablet) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 20px;
		> div {
			width: 100%;
		}
	}
}
