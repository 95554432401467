*,
::before,
::after {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	scroll-behavior: smooth;
	/* border: 1px solid black; */
}

:root {
	/* Brand Colors */

	/* Neutral Colours */
	--white: #f0f0f0;
	--black: #0d0907;
	--neutral-5: #d9d9d9;

	/* Secondary Colours */
	--lighter-grey: #b4b3b2;
	--light-grey: #908e8d;
	--dark-grey: #5d5a59;
	--darker-grey: #3d3a39;

	/* Text Colours */
	--primary-green-hover: #89c5407d;
	--primary-green: #8ac540;

	/* Text Size */
	--body-text: 15px;
	--button-text: 17px;
	--subheading-text: 11px;

	/* Selection Background */

	/* Footer Background */
	--footer-background: #5a4fcf;
}
.row0 {
	display: flex;
	flex-direction: row;
}
.row1 {
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.row2 {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.row {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.column0 {
	display: flex;
	flex-direction: column;
}
.column1 {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.column2 {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.column {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
@font-face {
	font-family: "Open Sans";
	src: url("./fonts/static/OpenSans-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "Ubuntu";
	src: url("./fonts/Ubuntu-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}
