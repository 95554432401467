$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.sectionThree {
	padding: 80px 0px 20px;
	&__top {
		justify-content: space-between;
		margin-bottom: 50px;
		&__textCluster {
			align-items: flex-start;
			> div {
				justify-content: flex-start;
				p {
					text-align: left;
				}
			}
			margin: 0px;
			width: 30%;
		}
		&__whyChooseCard {
			@media screen and (max-width: $breakpoint-desktop) {
				margin-bottom: 80px;
				width: 96%;
			}
		}
	}
	&__bottom {
		justify-content: space-between;
	}
}
.subDetails {
	@media screen and (max-width: $breakpoint-bigPhone) {
		font-size: 1.6rem;
	}
	margin-bottom: 35px;
	font-family: "Ubuntu";
	text-transform: capitalize;
	font-size: 2.1rem;
	&__green {
		color: var(--primary-green);
		font-weight: 600;
		margin: 0 10px;
	}
}
@media screen and (max-width: $breakpoint-desktop) {
	.sectionThree {
		&__top {
			flex-direction: column;
			margin-bottom: 0px;
			width: 100%;
			&__textCluster {
				margin-bottom: 80px;
				width: 100%;
			}
		}
		&__bottom {
			flex-direction: column;
		}
	}
}
