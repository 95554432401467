$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.button {
	padding: 13px 17px;
	border-radius: 9px;
	font-size: var(--button-text);
	text-transform: capitalize;
	font-weight: 600;
	font-family: "Open Sans";
	@media screen and (max-width: $breakpoint-bigPhone) {
		width: 100% !important;
	}
}
.fill {
	border: 1px solid transparent;
	background-color: var(--primary-green);
	color: white;
	transition: 0.5s;
	&:hover {
		background-color: var(--primary-green-hover);
		transition: 0.5s;
	}
}
.transparent {
	border: 1px solid black;
	background-color: transparent;
	&:hover {
		color: var(--primary-green-hover);
		border: 1px solid var(--primary-green-hover);
		transition: 0.5s;
	}
}
