$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.confirmation {
	padding: 80px 6% 0px;
	position: relative;
	background-color: white;
	&__top {
		width: 100%;
		justify-content: center;
		&__middleText {
			&__heading {
				margin: 40px 0px;
				font-size: 5vw !important;
				@media screen and (max-width: $breakpoint-bigPhone) {
					font-size: 1.5rem !important;
				}
				line-height: 1.5 !important;
				&__lightgrey {
					color: var(--light-grey);
				}
				&__halatech {
					font-weight: 600;
					color: var(--primary-green);
				}
				&__darkgrey {
					color: var(--darker-grey);
				}
				&__waitlist {
					color: var(--light-grey);
				}
			}
			.btn {
				margin-top: 35px;
			}
		}
	}

	&__bottom {
		position: absolute;
		top: 100%;
		img {
			width: 100vw;
			height: 40vh;
		}
		&__footerText {
			position: absolute;
			top: 85%;
			&__text {
				margin-bottom: 0px !important;
			}
		}
	}
}
.socials {
	width: 10%;

	> div {
		width: 100%;
		margin: 40px 0px;
		img {
			width: 50px;
			opacity: 0.5;
		}
	}
	&__end {
		justify-content: flex-end;
	}
	&__start {
		justify-content: flex-start;
	}
	@media screen and (max-width: $breakpoint-desktop) {
		display: none;
	}
}
.socials {
	width: 100%;
	position: absolute;
	z-index: -1;
	top: 0;

	@media screen and (max-width: $breakpoint-desktop) {
		display: none;
	}
}
