// @font-face {
// 	font-family: "Ortica";
// 	src: url("../../fonts/OrticaLinear-Light.woff2") format("woff2");
// 	font-weight: normal;
// 	font-style: normal;
// }
.heading {
	font-weight: 100;
	font-size: 2.8rem;
	line-height: 1.6;
	font-family: "Ubuntu";
}
