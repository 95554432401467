$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.topSection {
	padding: 35px 12%;
	justify-content: space-between;
	@media screen and (max-width: $breakpoint-tablet) {
		padding: 35px 6%;
	}
	> a {
		width: 20%;
		@media screen and (max-width: $breakpoint-tablet) {
			width: 120px;
		}
		img {
			width: 100%;
		}
	}
	.consulting {
		a {
			text-decoration: none;
			color: var(--black);
			margin-right: 8px;
			font-family: "Open Sans";
			font-size: 15px;
			font-weight: 600;
		}
	}
	.redirect {
		a {
			text-decoration: none;
			color: var(--primary-green);
			margin-right: 8px;
			font-family: "Open Sans";
			font-size: 15px;
			font-weight: 600;
		}
	}
}
