$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.sectionOne {
	padding: 20px 10% 200px;
	justify-content: space-between;
	&__left {
		width: 43%;
		&__paragraph {
			font-family: "Ubuntu";
			margin-top: 20px;
			font-size: 3.2vw;
			color: var(--dark-grey);
			line-height: 1.6;
			b {
				color: var(--darker-grey);
				font-weight: 800;
			}
			span {
				color: var(--primary-green);
				font-weight: 800;
			}
		}
	}
	&__right {
		width: 55%;
		justify-content: space-between;
		position: relative;
		img {
			width: 45%;
			border-radius: 7%;
		}
		&::before {
			content: "";
			background: linear-gradient(90deg, #d6d2ff 0%, #ffe6e4 100%);
			width: (110%);
			height: (110%);
			position: absolute;
			top: -5%;
			left: -5%;
			filter: blur(25px);
			z-index: -2;
		}
	}
}
@media screen and (max-width: $breakpoint-tablet) {
	.sectionOne {
		padding: 20px 10% 100px;
		flex-direction: column;
		&__left {
			width: 100%;
			margin-bottom: 60px;
			&__paragraph {
				font-size: 7.3vw;
			}
		}
		&__right {
			width: 100%;
		}
	}
}
