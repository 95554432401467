$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.description {
	text-align: center;
	font-size: var(--body-text);
	font-family: "Open Sans";
	line-height: 1.4;
	color: var(--dark-grey);
	@media screen and (max-width: $breakpoint-tablet) {
		width: 90% !important;
		font-size: 12px;
	}
}
