.overlappingBrands {
	margin-right: 40px;
	.brands {
		position: relative;
		img {
			height: 26px;
			width: 27px;
		}
		&__halatech {
		}
		&__satinRides {
			position: absolute;
			left: 125%;
			z-index: 3;
		}
		&__guidedHospitality {
			position: absolute;
			left: 70%;
			z-index: 2;
		}
	}
	.circularPlus {
		height: 27px;
		width: 27px;
		border: 1px solid var(--dark-grey);
		border-radius: 20px;
		font-size: large;
		background-color: white;
		position: relative;
		left: 25px;
		z-index: 4;
		color: var(--dark-grey);
	}
}
