$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.whyChooseHTACard {
	width: 30%;
	box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.263);
	height: 200px;
	border-radius: 7px;
	@media screen and (max-width: $breakpoint-desktop) {
		margin-bottom: 80px;
		width: 100%;
	}
	&__containerDiv {
		height: 100%;
		padding: 12px;
		box-shadow: 0px 0px 200px 0px #ffe6e4;
		&__heading {
			color: var(--primary-green);
			font-family: "Open Sans";
			text-transform: capitalize;
			font-size: 17px;
			margin: 15px 0px;
			
		}
		&__description {
			font-family: "Open Sans";
			color: var(--dark-grey);
			text-align: left;
            font-size: 13px;
		}
	}
}
