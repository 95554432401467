$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.sectionFive {
	padding: 50px 0px;
	.subDetails {
		@media screen and (max-width: $breakpoint-bigPhone) {
			font-size: 1.6rem;
		}
		text-align: center;
		margin-bottom: 35px;
		font-family: "Ubuntu";
		text-transform: capitalize;
		font-size: 2.1rem;
		&__green {
			color: var(--primary-green);
			font-weight: 600;
			margin-left: 10px;
		}
	}
	&__description {
		text-align: center;
		font-size: var(--body-text);
		font-family: "Open Sans";
		line-height: 1.4;
		color: var(--darker-grey);
		@media screen and (max-width: $breakpoint-tablet) {
			width: 100% !important;
		}
		span {
			font-weight: 600;
		}
	}
}
