$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.textSlider {
	position: relative;
	height: 30px;
	left: -14%;
    @media screen and (max-width: $breakpoint-desktop) {
        left: -17%;
        
    }
    @media screen and (max-width: $breakpoint-bigPhone) {
      left: -27%;
    }
}

.text {
	position: absolute;
	top: 0;
	width: 100%;
	opacity: 0;
	transition: opacity 1s ease-in-out;
	margin-top: 20px;
	font-style: italic;
	font-family: "Ubuntu";
    font-size: 5.5vw;
	@media screen and (max-width: $breakpoint-desktop) {
        font-size: 8.5vw;
	}
	@media screen and (max-width: $breakpoint-bigPhone) {
		font-size: 11.5vw;
	}
	&.visible {
		opacity: 1;
	}

	// animation: slideDown 3s linear infinite;
    animation: slideDown 3s linear infinite;

	&.automate {
		color: #8ac540;
	}

	&.engage {
		color: #5a4fcf;
	}

	&.elevate {
		color: #ff6f61;
	}
}

@keyframes slideDown {
	0% {
		transform: translateY(-30%);
	}
	25% {
		transform: translateY(0);
	}
	75% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(0%);
	}
}
