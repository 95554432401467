$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.sectionTwo {
	width: 100%;
	padding: 50px 0px;
	.subDetails {
		@media screen and (max-width: $breakpoint-bigPhone) {
			font-size: 1.6rem;
		}
		text-align: center;
		margin-bottom: 35px;
		font-family: "Ubuntu";
		text-transform: capitalize;
		font-size: 2.1rem;
		&__green {
			color: var(--primary-green);
			font-weight: 600;
			margin-left: 10px;
		}
	}
	&__textCluster {
		margin-bottom: 30px;
	}
	&__keyHighlights {
		width: 70%;
		margin: auto;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		column-gap: 10px;
		@media screen and (max-width: $breakpoint-desktop) {
			grid-template-columns: 1fr 1fr;
		}
		@media screen and (max-width: $breakpoint-bigPhone) {
			grid-template-columns: 1fr;
		}
		> div {
			width: 90%;
			margin-left: auto;
			margin-right: auto;
			display: grid;
		}
	}
}
