$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.consulting {
	padding: 0px 12%;
	background-color: white;
	&__heroHeader {
		margin: 25px 0px;
		font-size: 5vw !important;
		line-height: 1.3;
		color: var(--dark-grey);

		span {
			color: var(--black);
			font-weight: 600;
		}
	}
	&__heroImage {
		width: 90%;
		position: relative;
		@media screen and (max-width: $breakpoint-tablet) {
			width: 130%;
		}
		&__background {
			width: 100%;
		}
		&__hero {
			width: 50%;
			position: absolute;
			border-radius: 9%;
		}
	}
	&__heroBtn {
		margin-top: 40px;
	}
}
