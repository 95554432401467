$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.footer {
	background-color: var(--footer-background);
	color: var(--white);
	font-family: "Open Sans";
	&__top {
		padding: 80px 10%;

		&__left {
			border-right: 1px solid #f6f6f6;
			width: 50%;
			padding-right: 50px;
			height: 250px;
			justify-content: space-between;
			&__logo {
				justify-content: space-between;
				@media screen and (max-width: $breakpoint-desktop) {
					flex-direction: column;
					p {
						width: 100% !important;
					}
				}
				&__vector {
					width: 45%;
					height: 50%;
					@media screen and (max-width: $breakpoint-desktop) {
						width: 60%;
					
						margin-bottom: 40px;
					}
				}
				p {
					width: 50%;
					font-size: var(--body-text);
					text-transform: capitalize;
					line-height: 1.5;
				}
			}
			&__cookies {
				width: 50%;
				&__list {
					width: 100%;
					justify-content: space-between;
					list-style: none;

					li {
						font-size: var(--body-text);
						text-decoration: underline;
					}
				}
			}
		}
		&__right {
			width: 50%;
			margin-left: 50px;
			&__paragraph {
				font-family: "Ubuntu";
				font-size: 1.5rem;
				span {
					font-weight: 700;
				}
			}
			&__getAccessEmail {
				width: 100%;
				input {
					width: 90%;
					padding: 13px 15px;
					border-radius: 9px;
					border: 1px solid rgba(128, 128, 128, 0.433);
					@media screen and (max-width: $breakpoint-tablet) {
						width: 100%;
						margin-bottom: 20px;
					}
				}
				input::placeholder {
					color: var(--lighter-grey);
				}

				.btn {
					text-align: center;
					width: 45%;
					margin-top: 14px;
					@media screen and (max-width: $breakpoint-tablet) {
						display: flex;
						margin: auto;
						justify-content: center;
					}
				}
			}
		}
	}
	&__rightsReserved {
		padding: 30px 0px;
		border-top: 2px solid #f6f6f6;
		color: var(--white);
	}
}
@media screen and (max-width: $breakpoint-tablet) {
	.footer {
		&__top {
			flex-direction: column;
			align-items: flex-start;
			&__left {
				width: 100%;
				padding-right: 0px;
				border-right: none;
				padding-bottom: 50px;
				border-bottom: 1px solid #f6f6f6;
				&__logo {
					&__vector {
						width: 35%;
					}
				}
				&__cookies {
					width: 100%;
				}
			}
			&__right {
				width: 100%;
				margin-left: 0px;
				margin-top: 50px;
			}
		}
	}
}

@media (max-width: 680px) {
	.footer {
		&__top {
			&__right {
				&__paragraph {
					font-size: 1.3rem;
				}
			}
		}
		&__rightsReserved {
			font-size: 14px;
			flex-direction: column;
			align-items: center;
			.footerDot {
				display: none;
			}
		}
	}
}
@media (max-width: 480px) {
	.footer {
		&__rightsReserved {
			font-size: 12px;
		}
	}
}
