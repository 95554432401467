$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.metric {
	margin: 35px 0px 30px 0px;
	&__paragraph {
		word-spacing: 1.3px;
		color: var(--dark-grey);
		font-family: "Open Sans";
		font-size: 14px;
		text-transform: capitalize;
		font-weight: 600;
	}
	@media screen and (max-width: $breakpoint-tablet) {
		flex-direction: column;
		p {
			font-size: 12px;
			margin-top: 10px;
		}
	}
}
