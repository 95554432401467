$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.staff {
	padding: 50px 0px 0px;
	// background-color: white;
	&__heroHeader {
		margin: 25px 0px;
		font-size: 5vw;
		line-height: 1.3;
		&__scenes {
			color: var(--dark-grey);
		}
		&__team {
			color: var(--darker-grey);
			font-weight: 700;
		}
	}
	&__heroImage {
		width: 70%;
	}
}
