$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.home {
	position: relative;
	padding: 80px 6% 0px;
	background-color: white;
	&__top {
		width: 100%;
		&__middleText {
			&__heading {
				margin: 30px 0px;
				font-size: 5vw !important;
				@media screen and (max-width: $breakpoint-tablet) {
					font-size: 6vw !important;
				}
				line-height: 1.5 !important;
				&__lightgrey {
					color: var(--light-grey);
				}
				&__halatech {
					font-weight: 600;
				}
				&__darkgrey {
					color: var(--dark-grey);
				}
			}
		}
	}
	&__middle {
		width: 100%;
		input {
			width: 35%;
			padding: 13px 15px;
			border-radius: 9px;
			border: 1px solid rgba(128, 128, 128, 0.433);
			@media screen and (max-width: $breakpoint-tablet) {
				width: 100%;
				margin-bottom: 20px;
			}
		}
		input::placeholder {
			color: var(--lighter-grey);
		}
		@media screen and (max-width: $breakpoint-tablet) {
			flex-direction: column;
		}
		.btn {
			margin-left: 15px;
			text-align: center;
			@media screen and (max-width: $breakpoint-tablet) {
				margin-left: 0px;
				width: 50%;
				display: flex;
				margin: auto;
				justify-content: center;
			}
		}
	}

	&__bottom {
		width: 100%;
		img {
			width: 75%;
		}
		@media screen and (max-width: $breakpoint-tablet) {
			img {
				width: 100%;
			}
			width: 100%;
			margin: 40px 0px;
		}
	}
}
.socials {
	width: 100%;
	position: absolute;
	z-index: -1;
	top: 0;

	@media screen and (max-width: $breakpoint-desktop) {
		display: none;
	}
}

.textRotator {
	overflow: hidden;
	height: 80px;
	display: flex;
	justify-content: center;
	padding: 0px 10px;
	@media screen and (min-width: $breakpoint-mediumDesktop) {
		height: 120px;
	}
	@media screen and (max-width: $breakpoint-bigPhone) {
		height: 42px;
	}

	.list {
		display: flex;
		flex-direction: column;
		transition: transform 0.75s cubic-bezier(0.4, 0, 0.2, 1);
		.textContainer {
			display: flex;
			justify-content: center;
			span {
				white-space: nowrap;
				font-family: "Ubuntu";
				font-style: italic;
				font-size: 4.3rem;
				text-align: center;
				@media screen and (min-width: $breakpoint-mediumDesktop) {
					font-size: 6.3rem;
				}
				@media screen and (max-width: $breakpoint-bigPhone) {
					font-size: 2.3rem;
				}
			}
		}
	}
}
.poweredBy {
	margin-bottom: 30px;
}
