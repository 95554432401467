$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.serviceCard {
	width: 47%;
	box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.263);
	border-radius: 15px;
	@media screen and (max-width: $breakpoint-desktop) {
		margin-bottom: 80px;
		width: 100%;
	}
	&__containerDiv {
		width: 100%;
		box-shadow: 0px 0px 100px 0px #d6d2ff;
		border-radius: 15px;
		&__image {
			width: 100%;
			border-radius: 15px 15px 0px 0px;
		}
		&__text {
			margin-top: -5px;
			padding: 25px;
			background-color: white;
			border-radius: 0px 0px 15px 15px;
			&__heading {
				color: var(--primary-green);
				font-family: "Open Sans";
				text-transform: capitalize;
				margin-bottom: 15px;
				font-size: 17px;
			}
			&__description {
				font-family: "Open Sans";
				color: var(--light-grey);
				text-align: left;
			}
		}
	}
}
